(function () {

  /**
   * @ngdoc filter
   * @name kmi.lms.components.filter:yesNoFilter
   * @description
   * Format boolean value to Yes/No
   */
  angular.module('kmi.lms.components')
    .filter('yesNoFilter', yesNoFilter);

  function yesNoFilter() {

    function toBoolean(test){
      let trueSet = [true, 'true', 1, '1', 'Yes'];
      let falseSet = [false, 'false', 0, '0', 'No'];
      if(trueSet.indexOf(test) !== -1){
        return true;
      } else if(falseSet.indexOf(test) !== -1){
        return false;
      }
    }

    return function (value, backConvertation) {
      if(!backConvertation){
        if(toBoolean(value) === false) {
          return 'no';
        } else if(toBoolean(value) === true) {
          return 'yes';
        }
      } else {
        if(value === 'no') {
          return false;
        } else if(value === 'yes') {
          return true;
        }
      }
      return value;
    };
  }
})();
