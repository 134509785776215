import { Component, Input } from '@angular/core';


@Component({
  selector: 'required-field',
  templateUrl: './required-indicator.component.html',
})
export class RequiredIndicatorComponent {
  @Input() screenReaderText: string;
}
