(function () {

  angular.module('kmi.lms.components')
    .filter('intRange', function (_) {
      return function (input, min, max, padding) {
        var reverse = false, range;

        function zeroFill(number, width) {
          width -= number.toString().length;
          if (width > 0) {
            return new Array(width + (/\./.test(number) ? 2 : 1)).join('0') + number;
          }
          return number + ''; // always return a string
        }

        min = parseInt(min, 10);
        max = parseInt(max, 10);

        if (min > max) {
          reverse = true;
          min = [max, max = min][0];
        }

        range = _.range(min, max + 1);
        if (reverse) {
          range = range.reverse();
        }

        if (padding) {
          return _.map(range, function (num) {
            return zeroFill(num, padding);
          });
        } else {
          return range;
        }
      };
    });
})();
