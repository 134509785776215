import { Directive, ElementRef, forwardRef, Input } from '@angular/core';
import { NG_VALIDATORS, Validator, AbstractControl, ValidatorFn } from '@angular/forms';
import { LmsValidators } from './lms-validators.control';


@Directive({
  selector: '[lmsPattern]',
  providers: [
    { provide: NG_VALIDATORS, useExisting: forwardRef(() => LmsPatternDirective), multi: true }
  ]
})
export class LmsPatternDirective implements Validator {
  private validator: ValidatorFn;

  constructor(private el: ElementRef<HTMLInputElement>) { }

  @Input()
  set lmsPattern(value: string | RegExp) {
    this.validator = LmsValidators.pattern(value);
  }

  validate(control: AbstractControl): { [key: string]: any } | null {
    if (!control.value) {
      return !this.el.nativeElement.validity?.valid ? { pattern: true, message: 'Value is invalid.' } : null;
    }

    return this.validator(control);
  }
}
