import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IFileUploadOptions } from 'components/file-upload/file-upload.model';
import { NotificationService } from 'ajs/modules/app/environment/notification-service';


@Component({
  selector: 'file-input',
  templateUrl: './file-input.component.html'
})
export class FileInputComponent implements OnInit {
  @Input() resetOnSelection: boolean;
  @Input() disabled?: boolean;
  @Input() options: IFileUploadOptions = {
    maxFileSizeMb: 12,
    acceptFileTypes: '*/*'
  };

  @Output() fileSelected = new EventEmitter<File>();
  @Output() openTriggerChange = new EventEmitter<() => void>();

  openTrigger: () => void;
  tempFile: File = null;

  constructor(private notificationService: NotificationService) {
  }

  @Input()
  set inMemoryFile(value: File) {
    if (value) {
      this.setFile(value);
    }
  }

  ngOnInit() {
    this.openTrigger = () => {
      const input = this.getHtmlInput();

      this.clearHtmlInput(input);
      input.click();
    };

    this.openTriggerChange.emit(this.openTrigger);
  }

  onFileSelected(event: Event) {
    const input = event.target as HTMLInputElement;

    this.setFile(input.files[0]);

    if (this.resetOnSelection) {
      input.value = '';
      this.tempFile = null;
    }
  }

  resetFile(input: HTMLInputElement) {
    this.setFile();
    input.value = '';
  }

  private setFile(file?: File) {
    if (file) {
      if (!this.validateFileSize(file)) {
        this.notificationService.error('File size exceeds limit', 3e3);
        this.clearHtmlInput(this.getHtmlInput());

        file = null;
      }
    }

    this.tempFile = file;
    this.fileSelected.emit(file);
  }

  private getHtmlInput(): HTMLInputElement {
    return document.getElementById('tempFile') as HTMLInputElement;
  }

  private clearHtmlInput(input: HTMLInputElement) {
    input.value = '';
  }

  private validateFileSize(file: File) {
    if (!this.options) {
      return false;
    }

    return file.size <= (this.options.maxFileSizeMb || 12) * 1e+6;
  }
}
