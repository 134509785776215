(function () {

  angular.module('kmi.lms.components')
    .directive('dateSelector', function (moment, _) {
      return {
        template: require('ajs/components/dates/date-selector.html').default,
        restrict: 'EA',
        require: '?ngModel',
        scope: {
          isRequired: '='
        },
        link: function postLink($scope, element, attrs, ngModel) {
          $scope.minYear = 1915;
          $scope.currentYear = new Date().getFullYear();
          $scope.months = _.map(moment.months(), function(month, i){
            return {index: i, name: month};
          });

          $scope.birthDate = {};

          $scope.$watch(function(){
            return ngModel.$modelValue;
          },function(){
            if(ngModel.$viewValue){
              var date = moment.utc(ngModel.$viewValue);
              if(date && date.isValid()){
                $scope.birthDate.day = date.date();
                $scope.birthDate.month = date.month();
                $scope.birthDate.year = date.year();
              }
            }
          });

          $scope.birthChange = function(){
            if(typeof $scope.birthDate.day !== 'undefined' && $scope.birthDate.day !== null &&
            typeof $scope.birthDate.month !== 'undefined' && $scope.birthDate.month !== null &&
            typeof $scope.birthDate.year !== 'undefined' && $scope.birthDate.year !== null){
              var date = moment.utc({
                year: $scope.birthDate.year,
                month: parseInt($scope.birthDate.month, 10),
                day: $scope.birthDate.day,
                hour: 0,
                minute:0,
                second: 0
              });

              if(!date.isValid()){
                $scope.birthDate.day = 1;

                date = moment.utc({
                  year: $scope.birthDate.year,
                  month: parseInt($scope.birthDate.month, 10),
                  day: $scope.birthDate.day,
                  hour: 0,
                  minute:0,
                  second: 0
                });
              }

              ngModel.$setViewValue(date.format());
            }
            else{
              ngModel.$setViewValue(null);
            }
          };

          $scope.getMonthDays = function () {
            var year = ($scope.birthDate.year || $scope.minYear),
              month = (parseInt($scope.birthDate.month, 10) || 0);

            if(ngModel.$viewValue){
              var date = moment.utc(ngModel.$viewValue);
              if(date && date.isValid()){
                month = date.month();
                year = date.year();
              }
            }

            return _.range(1, new Date(year, month + 1, 0).getDate() + 1);
          };
        }
      };
    });
})();
