(function () {

  angular
    .module('kmi.lms.components')
    .factory('attributesService', attributesService);

  function attributesService(_, moment, customAttributesConstants, $http) {

    // object for saving a temporary state of attributes
    var attributeCache = {};

    return {
      formatResultData: formatResultData,
      mergeWithValues: mergeWithValues,
      initForm: initForm,
      getFormName: getFormName,
      validateMinCheckedValues: validateMinCheckedValues,
      saveToCache: saveToCache,
      getFromCache: getFromCache,
      clearCache: clearCache,
      initCache: initCache,
      prepareAttributesForSave: prepareAttributesForSave,
      checkUniqueAttrValue: checkUniqueAttrValue,
      getLookupValueName: getLookupValueName,
      clearModelAttributes: clearModelAttributes,
      prepareCreditAttributes: prepareCreditAttributes
    };

    /**
     * @description
     * Set correct date value for attributes
     * @param attributes
     */
    function formatResultData(attributes) {
      _.forEach(attributes, function (item) {
        if ((item.valueTypeId === 3) && !item.lookup && item.value) {
          item.value = moment(item.value).format('MM/DD/YYYY');
        }
      });
    }

    function mergeWithValues(attributes, attributesValues, searchProperty) {

      searchProperty = searchProperty || 'typeId';

      _.forEach(attributesValues, function (item) {
        var foundItem = _.find(attributes, _.matchesProperty(searchProperty, item[searchProperty]));
        if (foundItem) {
          angular.extend(foundItem, _.pick(item, ['specify', 'value', 'valueId']));
        }
      });
    }

    function initForm(category) {
      category.formName = getFormName(category);
      category.checkboxFieldName = category.formName + 'CheckboxValues';
      category.checkboxMinValueName = category.formName + 'CheckboxMinValue';
      category.primaryLabel = category.formName + 'Primary';

      category.errorMinMaxValuesMessage = [category.name, ' is not properly filled out! Please choose between ',
        category.minCheckedValues, '-', category.maxCheckedValues, ' attributes only.'].join('');
      category.errorPrimaryMessage = 'Please choose primary ' + category.name;
    }

    function getFormName(category) {
      var formName = customAttributesConstants.formPrefix;
      formName += category ? category.id : '';
      return formName;
    }

    /**
     * @description
     * Validate minimal checked value for category when 'checkbox' is true
     */
    function validateMinCheckedValues(category) {
      if (category.checkedValues < category.minCheckedValues) {
        category.minCheckedValuesValid = undefined;
      } else {
        category.minCheckedValuesValid = true;
      }
    }

    /**
     * @description
     * Set attribute to cache
     */
    function saveToCache(attribute) {
      var id = attribute.typeId || attribute.id;
      attributeCache[id] = attribute;
    }

    /**
     * @description
     * Get attribute from cache
     */
    function getFromCache(key) {
      return attributeCache[key];
    }

    /**
     * @description
     * Custom attribute cache cleaner
     */
    function clearCache(key) {
      if (key) {
        delete attributeCache[key];
      } else {
        attributeCache = {};
      }
    }

    /**
     * @description
     * Init cache from attributes array
     */
    function initCache(attributes) {
      clearCache();
      if (attributes) {
        _.each(attributes, function (attribute) {
          saveToCache(attribute);
        });
      }
      return attributes;
    }

    /**
     * @description
     * We need to clear from model all attributes that exists in categories.
     * Attributes that not exist in model needs to be saved.
     * Course has attributes fro categories and from credits
     * When credit is edited we synchronize changes to the model categories
     */
    function clearModelAttributes(model, categories) {
      let allAttributeIds = _.flatten(_.map(categories, category => _.map(category.attributes, 'id')));
      model.attributes = _.filter(model.attributes, attribute => !allAttributeIds.includes(attribute.id));
    }

    /**
     * @description
     * Prepare data of user.attributes before save/create user account.
     */
    function prepareAttributesForSave(model, categories, passAll) {
      _.each(categories, function (cat) {
        _.each(cat.attributes, function (attr) {
          let attrDraft = angular.copy(attr);
          let hasValue = attrDraft.value || attrDraft.value === false || attrDraft.valueId;

          let attributeFieldsForSend = [
            'active', 'id', 'typeId', 'lookup', 'primary', 'required', 'value', 'valueId', 'valueTypeId', 'categoryId'];

          if (hasValue && attrDraft.valueTypeId === 3) {
            attrDraft.value = moment(attrDraft.value).format('MM/DD/YYYY');
          }

          if (passAll || hasValue) {
            //pass attribute's fields with data only
            _.each(attrDraft, function (value, key) {
              if (attributeFieldsForSend.indexOf(key) === -1) {
                delete attrDraft[key];
              }
            });
            model.attributes = model.attributes || [];
            model.attributes.push(attrDraft);
          }

        });
      });
    }

    function checkUniqueAttrValue(typeId, value, instance) {
      if (instance === 'course') {
        return checkUniqueCourseAttrValue(typeId, value);
      } else {
        return checkUniqueUserAttrValue(typeId, value);
      }
    }

    function checkUniqueUserAttrValue(typeId, value) {
      return $http.post(['/a/user/attributes/', typeId, '/check_unique_value/'].join(''), {value: value})
        .then(function (response) {
          return response.data;
        });
    }

    function checkUniqueCourseAttrValue(typeId, value) {
      return $http.post(['/a/custom_attributes/courses/', typeId, '/check_unique_value/'].join(''), {value: value})
        .then(function (response) {
          return response.data;
        });
    }

    function getLookupValueName(attribute) {
      if (!attribute.lookup) {
        return false;
      }
      var selectedLookupValue = _.find(attribute.lookupValues, value => {
        return value.id === attribute.valueId;
      });
      return selectedLookupValue.value;
    }

    function prepareCreditAttributes(course, creditType, availableCreditAttributes) {
      if (_.get(creditType, 'courseAttributes.length')) {
        creditType.courseAttributes = _.map(creditType.courseAttributes, courseAttribute => {
          var courseAttributeType = _.find(availableCreditAttributes, aca => aca.id === courseAttribute.courseAttributeType.id);
          if (!courseAttributeType) {
            courseAttributeType = courseAttribute.courseAttributeType;
            availableCreditAttributes.push(courseAttributeType);
          }
          courseAttributeType.required = courseAttributeType.required || courseAttribute.required;
          return courseAttributeType;
        });
        if (_.get(course, 'attributes.length')) {
          mergeWithValues(creditType.courseAttributes, course.attributes, 'id');
        }
      }
    }
  }
})();
