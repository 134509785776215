(function () {

  angular.module('kmi.lms.components')
    .directive('validWord', function () {
      return {
        restrict: 'A',
        require: 'ngModel',
        link: function (scope, element, attr, ngModelController) {
          ngModelController.$parsers.unshift(function (viewValue) {
            if (!viewValue) {
              ngModelController.$setValidity('word', true);
              return null;
            }

            var wordRegEx = /^[A-Za-z0-9]+$/;

            if (wordRegEx.test(viewValue)) {
              ngModelController.$setValidity('word', true);
            } else {
              ngModelController.$setValidity('word', false);
            }

            return viewValue;
          });
        }
      };
    });
})();
