(function () {

  angular
    .module('kmi.lms.components')
    .component('modalText', {
      template: require('ajs/components/modal-text/modal-text.html').default,
      controller: ModalTextController,
      controllerAs: 'vm',
      bindings: {
        modalInstance: '<',
        resolve: '<'
      }
    });

  /* @ngInject */
  function ModalTextController() {
    var vm = this;

    vm.cancel = cancel;

    vm.$onInit = function() {
      vm.text = vm.resolve.text;
    };

    function cancel() {
      vm.modalInstance.dismiss('cancel');
    }
  }

})();
