(function () {

  angular
    .module('kmi.lms.components')
    .component('markdownEditorComponent', {
      template: require('ajs/components/markdown/markdown-editor-component.upgradable.html').default,
      controller: MarkdownEditorComponentController,
      controllerAs: 'vm',
      bindings: {
        id: '=?',
        content: '<?',
        rows: '=?',
        required: '=?',
        disabled: '=?',
        html: '=',
        isPreview: '<?',
        placeholder: '=?',
        disableAttachments: '<?',
        maxLength: '=?',
        contentChange: '&',
        hideButtons: '<?'
      }
    });

  /* @ngInject */
  function MarkdownEditorComponentController() {
    var vm = this;

    vm.$onInit = onInit;
    vm.onChange = onChange;

    function onInit() {
      if(!vm.content) {
        vm.content = '';
      }

      if(!vm.rows) {
        vm.rows = 10;
      }
    }

    function onChange() {
      if (vm.contentChange) {
        vm.contentChange(vm.content);
      }
    }
  }
})();
