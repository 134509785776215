(function(){

  angular.module('kmi.lms.components')
    .directive('autoTabTo', autoTabTo);

  function autoTabTo() {
    return {
      restrict: 'A',
      link: function (scope, el, attrs) {
        el.bind('keyup', function() {
          if (this.value.length === this.maxLength) {
            var element = document.getElementsByName(attrs.autoTabTo);
            if (element && element.length) {
              element[0].focus();
            }
          }
        });
      }
    };
  }
})();
