(function () {
  angular.module('kmi.lms.core')
    .factory('scrollingService', function ($window, rootScopeService) {
      return {
        attachScrollListener: attachScrollListener,
        detachScrollListener: detachScrollListener
      };

      function attachScrollListener() {
        // bind to window scroll even
        angular.element($window).bind('scroll', scrollHandler);
      }

      function detachScrollListener() {
        angular.element($window).unbind('scroll', scrollHandler);
      }

      function scrollHandler() {
        if ((<any>window).$($window).scrollTop() + 2 * (<any>window).$($window).height() >= angular.element('body')[0].scrollHeight) {
          rootScopeService.broadcast('content.when-scrolled');
        }
      }
    });

})();
