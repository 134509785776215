import { ControlValueAccessor } from '@angular/forms';


export abstract class LmsValueAccessor<T> implements ControlValueAccessor {
  value: T;
  disabled = false;
  touched = false;

  protected validatorChange: () => void;
  protected onChange: (v: T) => void;
  protected onTouched: () => void;

  writeValue(value: T) {
    this.value = value;
  }

  registerOnChange(onChange: (value: T) => void): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: () => any): void {
    this.onTouched = onTouched;
  }

  registerOnValidatorChange?(fn: () => void): void {
    this.validatorChange = fn;
  }

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  setValue(value: T) {
    if (this.onChange) {
      this.value = value;
      this.onChange(value);
      this.markAsTouched();
    }
  }
}
