import { Injectable } from '@angular/core';
import { NgbDatepickerConfig } from '@ng-bootstrap/ng-bootstrap';


/**
 * A configuration service for the `NgbDatepicker` component.
 * https://ng-bootstrap.github.io/#/components/datepicker/examples#config
 * - `minDate`: is not working for validation, so that it is required to pass this input into a controller.
 * - `maxDate`: is not working for validation, so that it is required to pass this input into a controller.
 */
@Injectable()
export class DatepickerConfig extends NgbDatepickerConfig {
  weekdays = 1;
  firstDayOfWeek = 7;
  minDate = { year: 1900, month: 1, day: 1 };
  maxDate = { year: 2399, month: 12, day: 31 };
}
