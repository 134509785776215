(function () {

  angular.module('kmi.lms.components')
    .directive('uniqueEmail', function ($http, $q) {
      var canceler;

      return {
        restrict: 'A',
        require: 'ngModel',
        link: function (scope, elem, attr, ctrl) {
          var user = scope.$eval(attr.user);

          ctrl.$asyncValidators.uniqueEmail = function (newEmail) {
            var deferred = $q.defer();
            if (!newEmail || !user || user.originalEmail === newEmail) {
              deferred.resolve();
            } else {
              if (canceler) {
                canceler.resolve({canceled: true});
              }
              canceler = $q.defer();
              user.checkEmail(newEmail, user.active, {timeout: canceler.promise}).then(function (unique) {
                if (unique) {
                  deferred.resolve();
                } else {
                  deferred.reject();
                }
              });
            }
            return deferred.promise;
          };

        }
      };
    });
})();
