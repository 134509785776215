import { Directive, ElementRef, HostListener, Input } from '@angular/core';


@Directive({
  selector: '[scrollTo]'
})
export class ScrollToDirective {
  @Input() scrollTo: string;
  @Input() scrollOffset?: number = 0;
  @Input() offsetAll?: number = 0;

  constructor(
    private elementRef: ElementRef<HTMLInputElement>,
    private document: Document,
    private window: Window
  ) {
  }

  @HostListener('click')
  clicked() {
    setTimeout(() => {
      let el = this.document.getElementById(this.scrollTo);

      if (!el) {
        const nel = this.document.getElementsByName(this.scrollTo);

        el = nel && nel.length ? el[0] : null;
      }

      if (el) {
        el.scrollIntoView({
          behavior: 'smooth',
          inline: 'nearest',
          block: 'center'
        });

        if (this.scrollOffset) { //then move down window by size of offset of elem
          const offsetTop = this.offsetAll ? this.getOffsetTop(el) : el.getBoundingClientRect().top;

          this.window.scrollTo({
            left: 0,
            top: offsetTop - this.scrollOffset,
            behavior: 'smooth',
          });
        }
      }
    }, 1);
  }

  getOffsetTop(element) {
    return element ? (element.offsetTop + this.getOffsetTop(element.offsetParent)) : 0;
  }
}
