(function () {

  angular
    .module('kmi.lms.components')
    .directive('bindHtmlCompile', function ($compile, $sce) {
      return {
        restrict: 'A',
        link: function (scope, element, attrs) {
          scope.$watch(function () {
            return scope.$eval(attrs.bindHtmlCompile);
          }, function (value) {
            value = $sce.trustAsHtml(value);

            element.html(value && value.toString());
            // If scope is provided use it, otherwise use parent scope
            var compileScope = scope;
            if (attrs.bindHtmlScope) {
              compileScope = scope.$eval(attrs.bindHtmlScope);
            }
            $compile(element.contents())(compileScope);
          });
        }
      };
    });

})();
