(function () {

  angular.module('kmi.lms.components')
    .directive('ngThumb', ['fileService', function (fileService) {
      return {
        restrict: 'A',
        require: '?ngModel',
        template: '<canvas/>',
        link: function (scope, element, attributes, ngModel) {
          var params = scope.$eval(attributes.ngThumb);

          scope.$watch(function () {
            return ngModel.$modelValue;
          }, function () {
            if (!ngModel.$modelValue) {
              element.hide();
              return;
            }

            if (!(fileService.fileReaderSupported() && fileService.isFile(ngModel.$modelValue) &&
              fileService.isImage(ngModel.$modelValue))) {
              element.hide();
              return;
            }

            var canvas = element.find('canvas');
            var reader = new FileReader();

            function onLoadFile(event) {
              var img = new Image();

              img.onload = function () {
                var ratio = Math.min((params.width || img.width) / img.width, (params.height || img.height) /
                  img.height);
                var width = img.width * ratio;
                var height = img.height * ratio;
                canvas.attr({
                  width: width,
                  height: height
                });
                canvas[0].getContext('2d').drawImage(img, 0, 0, width, height);
              };

              img.src = event.target.result;
              element.show();
            }

            reader.onload = onLoadFile;
            reader.readAsDataURL(ngModel.$modelValue);
          });

        }
      };
    }]);
})();
