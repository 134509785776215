(function () {

  angular.module('kmi.lms.components')
    .component('score', {
      template: require('ajs/components/score.html').default,
      controller: ScoreController,
      controllerAs: 'vm',
      bindings: {
        points: '<',
        percentage: '<'
      }
    });

  function ScoreController(elms) {
    let vm = this;

    vm.$onInit = onInit;
    vm.$onChanges = onChanges;

    function onInit() {
      vm.points = elms.formatDigit(vm.points);
      vm.percentage = elms.formatDigit(vm.percentage);
      vm.showSeparator = !!vm.points && !!vm.percentage && !vm.noSeparator;
    }

    function onChanges(changes) {
      if (changes.hasOwnProperty('points')) {
        vm.points = elms.formatDigit(vm.points);
      }

      if (changes.hasOwnProperty('percentage')) {
        vm.percentage = elms.formatDigit(vm.percentage);
      }

      vm.showSeparator = !!vm.points && !!vm.percentage;
    }
  }
})();
