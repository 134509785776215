angular.module('kmi.lms.components')
  .constant('fileUploadConfig', {
    image: {
      endPoint: '',
      maxFileSize: 12,
      fileType: '.bmp,.gif,.jpg,.jpeg,.png,.svg'
    },
    video: {
      endPoint: '',
      maxFileSize: 12,
      fileType: 'video/*'
    },
    excel: {
      endPoint: '',
      maxFileSize: 12,
      fileType: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
    }
  })
  .component('singleFileUpload', {
    template: '<div ng-form="vm.uploadForm">' +
      '<input type="file" aria-label="Upload File" id="{{vm.idEl}}" file-input="vm.files"\n' +
      '    accept="{{vm.config.fileType}}"\n' +
      '    style="display: none"/>' +
      '</div>',
    transclude: true,
    controller: SingleFileUploadController,
    controllerAs: 'vm',
    bindings: {
      configName: '<',
      model: '=',
      idEl: '=?',
      globalId: '=',
      file: '=',
      progressCallback: '&',
      errorCallback: '&?',
      openTrigger: '=',
      overrideConfig: '=',
      uploadingFlag: '='
    }
  });

function SingleFileUploadController(
  $scope, fileUploadConfig, fileUploadService, $timeout, urlVersionService, _
) {
  var vm = this;

  vm.$onInit = function () {
    vm.openTrigger = () => {
      if (vm.files && vm.files.length) {
        angular.element(document.getElementById(vm.idEl)).val('');
      }

      $timeout(() => {
        angular.element(document.getElementById(vm.idEl)).trigger('click');
      });
    };

    vm.idEl = 'fileElem_' + Math.random();
    vm.config = angular.extend({}, fileUploadConfig[vm.configName], vm.overrideConfig);

    if (!vm.config) {
      throw new Error('Undefined file uploader config name');
    }

    $scope.$watchCollection('vm.files', function (newValue, oldValue) {
      if (!_.isEqual(newValue, oldValue)) {
        if (vm.files && vm.files.length) {
          uploadFile();
        } else {
          vm.model = null;
        }
        vm.uploadForm.$setDirty();
      }
    });
  };

  function uploadFile() {
    vm.uploadingFlag = true;

    let service = fileUploadService.getService(vm.config);
    service.upload(vm.files[0], vm.globalId, vm.progressCallback, vm.config.formData)
      .then((result) => {
        vm.model = _.trim(result.data, '"');
        vm.file = vm.files[0];
        showError(null);
      }, (response) => {
        let errorMessage = response.data || response;

        if (response && response.headers) {
          if (response.headers('Content-Type') === 'text/html') {
            errorMessage = 'The remote server is not available at this time. Try again later.';
          }
        }

        showError(errorMessage);

        vm.resetFile();
      })
      .finally(() => {
        vm.uploadingFlag = false;
        urlVersionService.update();
      });
  }

  vm.resetFile = resetFile;

  function resetFile() {
    const file = document.getElementById(vm.idEl) as HTMLInputElement;
    file.value = '';
    vm.file = null;
  }

  function showError(errorMessage) {
    if (vm.errorCallback) {
      vm.errorCallback({errorMessage: errorMessage});
    }
  }
}
