import { Injectable } from '@angular/core';
import { NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';


/**
 * This Service handles how the date is rendered and parsed from keyboard i.e. in the bound input field.
 * https://ng-bootstrap.github.io/#/components/datepicker/examples#adapter
 */
@Injectable()
export class DatepickerParserFormatter extends NgbDateParserFormatter {
  readonly DELIMITER = '/';

  parse(value: string): NgbDateStruct | null {
    if (value) {
      const [month, day, year] = value.split(this.DELIMITER);

      return {
        month: parseInt(month, 10),
        day: parseInt(day, 10),
        year: parseInt(year, 10),
      };
    }

    return null;
  }

  /* Format: MM/DD/YYYY */
  format(date: NgbDateStruct | null): string {
    return date
      ? this.pad(date.month) + this.DELIMITER + this.pad(date.day) + this.DELIMITER  + date.year
      : '';
  }

  private pad(value: number): string {
    return String(value).padStart(2, '0');
  }
}
