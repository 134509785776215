(function () {

  angular.module('kmi.lms.components')
    .component('attributeInfo', {
      template: require('ajs/components/attributes/attribute-info-template.html').default,
      controllerAs: 'vm',
      controller: AttributesInfoController,
      bindings: {
        attribute: '<',
        showInternalCode: '<?'
      }
    });

  /* @ngInject */
  function AttributesInfoController(formService, notificationService, attributesService) {
    var vm = this;
    vm.getLookupValueName = attributesService.getLookupValueName;

    vm.copyInternalCode = copyInternalCode;

    function copyInternalCode() {
      formService.copyToClipboard(vm.attribute.slug)
        .then(function () {
          notificationService.info('Internal code copied to clipboard', 3e3);
        });
    }
  }

})();
