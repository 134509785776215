(function () {

  angular.module('kmi.lms.components')
    .component('dateTime', {
      template: require('ajs/components/dates/date-time.html').default,
      controller: DateTimeController,
      controllerAs: 'vm',
      bindings: {
        date: '=',
        timeZone: '<?',
        delimiter: '@?',
        hideTz: '<?'
      }
    });

  /* @ngInject */
  function DateTimeController(moment) {
    let vm = this;

    vm.$onInit = onInit;

    function onInit() {
      if (vm.date && moment.isMoment(vm.date)){
        // Convert to base string format to allow angular to use it in bindig
        vm.date = vm.date.toISOString();
      }
      vm.timeZone = vm.timeZone || moment.tz.guess();
      vm.timeZoneAbbr = moment.tz(vm.date, vm.timeZone).zoneAbbr();
    }
  }
})();
