(function () {

  angular.module('kmi.lms.components')
    .directive('elmsFileUpload', function () {
      return {
        restrict: 'EA',
        require: '?ngModel',
        template: require('ajs/components/file-upload/file-upload.html').default,
        compile: function compile() {
          return {
            pre: function preLink(scope, iElement, iAttrs) {
              scope.dropZone = iElement.find('.drop-zone');
              scope.model = { file: null};
              scope.options = scope.$eval(iAttrs.elmsFileUpload);
              scope.required = iAttrs.hasOwnProperty('required');
            },
            post: function (scope, iElement, iAttrs, ngModel) {
              scope.$watch('model.file', function (value, oldValue) {
                if (value !== oldValue) {
                  ngModel.$setViewValue(value);
                }
              });
            }
          };
        }
      };
    });
})();
