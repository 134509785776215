import { Component, ElementRef, Input, Renderer2, ViewChild } from '@angular/core';


export interface IThumbnailParams {
  width?: number;
  height?: number;
}

@Component({
  selector: 'thumbnail',
  template: '<canvas #cnv></canvas>',
  styles: [':host {display: flex;place-content: center;}']
})
export class ThumbnailComponent {
  @ViewChild('cnv') canvasElement: ElementRef;
  @Input() params: IThumbnailParams;
  @Input() strictSize = true;
  thumbnailVal?: Blob;

  constructor(private renderer: Renderer2) {
  }

  @Input() set file(value: Blob) {
    this.thumbnailVal = value;
    this.reloadImage();
  }

  reloadImage() {
    const reader = new FileReader();

    reader.onload = this.onLoadFile.bind(this);
    reader.readAsDataURL(this.thumbnailVal);
  }

  onLoadFile(event) {
    const canvas = this.canvasElement.nativeElement;
    const img = new Image();

    img.onload = () => {
      const ratio = Math.min((this.params?.width || img.width) / img.width, (this.params.height || img.height) /
        img.height);
      const width = this.strictSize ? this.params?.width : img.width * ratio;
      const height = this.strictSize ? this.params?.height : img.height * ratio;

      this.renderer.setProperty(canvas, 'width', width);
      this.renderer.setProperty(canvas, 'height',  height);

      canvas.getContext('2d').drawImage(img, 0, 0, width, height);
    };

    img.src = event.target.result;
  }
}
