(function () {

  angular.module('kmi.lms.components')
    .directive('modelToDate', modelToDate);

  /* @ngInject */
  function modelToDate($parse, moment) {
    var directive = {
      restrict: 'A',
      priority: 1,
      require: 'ngModel',
      link: link
    };
    return directive;

    function link(scope, element, attrs, ctrl) {
      scope.$watch(function () {
        return ctrl.$modelValue;
      }, fixModel);

      fixModel();

      function fixModel() {
        var model = $parse(attrs.ngModel);
        var currentValue = $parse(attrs.ngModel)(scope);
        if (currentValue && !(currentValue instanceof Date)) {
          model.assign(scope, moment(currentValue).toDate());
        }
      }
    }
  }
})();
