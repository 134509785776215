import { Injectable } from '@angular/core';
import { NgbDateAdapter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';


/**
 * This Service handles how the date is represented in scripts i.e. ngModel.
 * https://ng-bootstrap.github.io/#/components/datepicker/examples#adapter
 */
@Injectable()
export class DatepickerAdapter extends NgbDateAdapter<string> {
  readonly DELIMITER = '-';

  /* @value: YYYY-MM-DD HH:mm */
  fromModel(value: string | null | Date): NgbDateStruct | null {
    if (value && typeof value === 'string') {
      const [date] = value.split(/[\sTt]/);
      const [month, day, year] = (date || value).split(this.DELIMITER);

      return {
        year: parseInt(month, 10),
        month: parseInt(day, 10),
        day: parseInt(year, 10)
      };
    } else if (value instanceof Date) {
      return {
        year: value.getFullYear(),
        month: value.getMonth() + 1,
        day: value.getDate()
      };
    }

    return null;
  }

  /* Format: YYYY-MM-DD */
  toModel(date: NgbDateStruct | null): string | null {
    return date && date.year >= 0 && date.month >= 0 && date.day >= 0
      ? date.year + this.DELIMITER + this.pad(date.month) + this.DELIMITER + this.pad(date.day)
      : null;
  }

  private pad(value: number): string {
    return String(value).padStart(2, '0');
  }
}
