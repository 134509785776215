import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FileUploadService } from 'components/file-upload/file-upload.service';
import { IUploadedTmpFile } from 'components/file-upload/single-file-upload.upgraded';
import { catchError, finalize, of } from 'rxjs';
import { ElmsUtils } from 'core/utils';
import { IFileUploadOptions } from 'components/file-upload/file-upload.model';
import { ControlContainer, NgModelGroup } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';


@Component({
  selector: 'image-upload',
  templateUrl: './image-upload.html',
  viewProviders: [{ provide: ControlContainer, useExisting: NgModelGroup }],
})
export class ImageUploadComponent {
  @Output() tmpFileChange: EventEmitter<IUploadedTmpFile> = new EventEmitter<IUploadedTmpFile>();
  @Output() changed: EventEmitter<IUploadedTmpFile> = new EventEmitter<IUploadedTmpFile>();
  @Input() uploadedImage?: File;
  @Input() options: IFileUploadOptions;

  @Input() simpleView?: boolean = false;
  @Input() required?: boolean = false;

  imageRecommendationOpen = false;


  uploadInProgress = false;
  fileUploadError?: string;
  uploadProgress?: number;
  private _tmpFile: IUploadedTmpFile;

  constructor(private fileUploadService: FileUploadService) { }

  @Input() set tmpFile(file: IUploadedTmpFile) {
    this.uploadedImage = file?.file;
    this._tmpFile = file;
  }

  get tmpFile() {
    return this._tmpFile;
  }

  onFileSelected(event: Event) {
    const input = event.target as HTMLInputElement;

    if (input.files && input.files.length) {
      const file = input.files[0];

      this.uploadToServer(file);
    }
  }

  onDrop(event: DragEvent) {
    event.preventDefault();

    if (event.dataTransfer && event.dataTransfer.files) {
      const file = event.dataTransfer.files[0];

      this.uploadToServer(file);
    }
  }

  onDragOver(event: DragEvent) {
    event.stopPropagation();
    event.preventDefault();
  }

  removeImage() {
    this.uploadedImage = undefined;
    this.uploadProgress = undefined;
    this.uploadInProgress = false;
    this.changed.emit(undefined);
    this.tmpFileChange.emit(undefined);
  }

  formatFileSize(size: number): string {
    return ElmsUtils.formatBytes(size);
  }

  private uploadToServer(file: File) {
    const data = Object.assign(this.options, {
      trackProgress: (process) => {
        this.uploadProgress = process;
      }
    });

    this.uploadInProgress = true;
    this.fileUploadError = undefined;
    this.fileUploadService.upload(file, data, true)
      .pipe(
        finalize(() => {
          this.uploadInProgress = false;
        }),
        catchError((err: HttpErrorResponse) => {
          this.fileUploadError = err.error;
          this.uploadedImage = undefined;
          this.uploadProgress = undefined;
          this.uploadInProgress = false;

          return of(null);
        }))
      .subscribe((result) => {
        if (result) {
          this.uploadedImage = file;
          const tmpFile = {
            lastModified: 1,
            lastModifiedDate: new Date(),
            name: result,
            size: file.size,
            type: file.type,
            file: file
          };

          this.tmpFileChange.emit(tmpFile);
          this.changed.emit(tmpFile);
        }
      });
  }
}
