(function () {

  angular.module('kmi.lms.components')
    .directive('emailAttachmentsDeprecated', emailAttachments);

  /** @deprecated Please use `src.app.modules.emails.components.EmailAttachmentsComponent` */
  function emailAttachments() {
    return {
      restrict: 'EA',
      scope: {
        email: '='
      },
      template: require('ajs/components/emails/email-attachments.html').default,
      controller: EmailAttachmentController,
      controllerAs: 'vm',
      bindToController: true
    };
  }

  /* @ngInject */
  function EmailAttachmentController($scope, $http, elms, _) {
    var vm = this,
      tempFileEndpoint;

    vm.removeAttachment = removeAttachment;
    vm.formatFileSize = elms.formatBytes;

    vm.$onInit = activate;

    function activate() {
      tempFileEndpoint = '/a/emails/' + vm.email.globalId + '/attachments/temp/';

      vm.fileUploadOptions = {
        url: tempFileEndpoint,
        fileMaxSize: 10
      };

      $scope.$watch('vm.attachment', function (value, oldValue) {
        if (value === oldValue) {
          return;
        }

        if (vm.attachment) {
          vm.email.attachments.push({name: vm.attachment.name, tmpName: vm.attachment.tmpName, size: vm.attachment.size});
          $scope.$broadcast('event:clearBlob');
        }
      });
    }

    function removeAttachment(attachmentFile) {
      vm.email.attachments = _.without(vm.email.attachments, attachmentFile);

      $http
        .delete(tempFileEndpoint, {name: attachmentFile.tmpName});
    }
  }
})();
