<div name="imageUploadForm">
  <div *ngIf="!uploadedImage && !uploadInProgress">
    <div class="form-group row">
      <div class="col-xs-12 drop-zone-wrapper">
        <div *ngIf="!simpleView ">
          <button type="button" class="well text-center drop-zone fileinput-button hidden-xs col-sm-12"
                  (click)="imageUploadInput.click();"
                  (drop)="onDrop($event)"
                  (dragover)="onDragOver($event)"
                  [ngClass]="{required: required}">
            <div>
            <span><span
              class="fa fa-file-image"></span>&nbsp;<strong>Drop image here or <em>click</em> to upload</strong> </span>
            </div>
          </button>
          <input type="file" name="file" #imageUploadInput accept="{{options.acceptFileTypes}}" aria-label="file"
                 (change)="onFileSelected($event)" hidden>
        </div>
        <div  [ngClass]="{'visible-xs': !simpleView }">
                    <span class="btn btn-link fileinput-button no-padding-all border-0">
                      <label for="file" class="sr-only">Upload image here</label>
                      <span class="fa-regular fa-file-image xs-margin-right inline-block"></span>Attach an image
                      <input type="file" id="file" name="file" (change)="onFileSelected($event)"
                             accept="{{options.acceptFileTypes}}" aria-label="file">
                    </span>

          <button type="button" title="Image recommendations" aria-label="Image recommendations"
                  class="btn btn-link form-help-link no-padding-all no-text-decoration"
                  (click)="imageRecommendationOpen=!imageRecommendationOpen"><span
            class="fa fa-question-circle"></span></button>
        </div>

        <div class="col-sm-offset-3 col-sm-9" *ngIf="required">
          <input type="hidden" [ngModel]="uploadedImage.name" id="uploadedFileName" name="uploadedFileName"
                 #uploadedFileName='ngModel' class="form-control required" required/>
          <p class="help-block" *ngIf="uploadedFileName.invalid && uploadedFileName.dirty">
            <span *ngIf="uploadedFileName.errors?.required">Image is required.</span>
          </p>
        </div>
      </div>

      <div class="col-sm-9 has-error" *ngIf="fileUploadError">
        <p class="help-block">
          <span>{{fileUploadError}}</span>
        </p>
      </div>

      <div class="col-xs-12 xs-margin-top" *ngIf="imageRecommendationOpen">
        <div class="panel panel-help" *ngIf="imageRecommendationOpen">
          <div class="panel-heading">
            <button type="button" class="close" aria-label="Image recommendations"
                    title="Image recommendations"
                    (click)="imageRecommendationOpen=!imageRecommendationOpen"><span
              aria-hidden="true">&times;</span></button>
            Image recommendations
          </div>
          <div class="panel-body">
            For the best quality on desktop image should be 860px wide. It will be scaled down on mobile devices to
            fit width.<br>
            Acceptable file formats is: <em>*.gif, *.jpeg, *.jpg, *.png, *.bmp, *.svg</em>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="uploadProgress || uploadInProgress">
    <div class="form-group no-margin-bottom">
      <div class="col-xs-12">
        <div class="form-control-static">
          <div class="progress">
            <div class="progress-bar"
                 [ngClass]="{'progress-bar-warning progress-striped active':uploadProgress < 100, 'progress-bar-success':uploadProgress >= 100}"
                 [ngStyle]="{'width': uploadProgress + '%'}">
              <span>{{uploadProgress}}%</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="uploadedImage">
    <div class="form-group">
      <div class="col-sm-4">
        <div class="d-flex">
          <thumbnail [file]="uploadedImage" [params]="{width: 180, height:180 }" [strictSize]="false"
                     class="thumbnail-image thumbnail-image-preview"></thumbnail>
        </div>
      </div>

      <div class="col-sm-4">
        <p class="form-control-static">
          size: <strong>{{formatFileSize(uploadedImage.size)}}</strong><br/>
          type: {{uploadedImage.type}}
        </p>
      </div>

      <div class="col-sm-4">
        <button type="button" class="btn btn-danger btn-block" (click)="removeImage()" *ngIf="uploadedImage">
          Remove image
        </button>
      </div>
    </div>
  </div>
</div>
