(function () {

  angular
    .module('kmi.lms.components')
    .component('copyLinkDialog', {
      template: require('ajs/components/forms/copy-link-component.html').default,
      controller: CopyLinkController,
      controllerAs: 'vm',
      bindings: {
        modalInstance: '<',
        resolve: '<'
      }
    });

  /* @ngInject */
  function CopyLinkController($scope, formService, notificationService) {
    var vm = this;

    vm.$onInit = onInit;

    vm.cancel = cancel;
    vm.copy = copy;

    function onInit() {
      vm.title = vm.resolve.title || 'Copy Link to clipboard';
      vm.link = vm.resolve.link;
    }

    function copy() {
      formService.copyToClipboard(vm.link)
        .then(function () {
          $scope.$evalAsync(function () {
            notificationService.visibleInfo(false);
            notificationService.info('Copied to clipboard', 3e3);
          });
        });
    }

    function cancel() {
      vm.modalInstance.dismiss('cancel');
    }
  }
})();
