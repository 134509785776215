<div class="dropdown select-dropdown"   ngbDropdown>
  <button class="btn btn-default btnblock dropdown-toggle"
          aria-label="Toggle-dropdown"
          style="background-color: #ffffff"
          type="button"
          ngbDropdownToggle
>

  <div class="flex flex-no-padding">
      <div class="flex-row">
        <div class="flex-col flex-col-grow">
            <div id="dropdown-toggle-text" class="dropdown-toggle-text text-truncate text-left" [innerHtml]="selectedValue | markdownToHtml"></div>
          </div>
          <div class="flex-col flex-col-1x">
            <div class="text-right">
              <span class="fa fa-sort"></span>
            </div>
          </div>
        </div>
      </div>

  </button>


  <div class="dropdown-menu"  ngbDropdownMenu role="menu">
    <a href="#" ngbDropdownItem
       class="use-hand-cursor"
       (click)="selectOption(option)"
       [innerHtml]="option.key | markdownToHtml"
       *ngFor="let option of values"></a>
  </div>
</div>


