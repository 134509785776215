(function () {

  angular
    .module('kmi.lms.components')
    .factory('formService', formService);

  function formService(_, $q, $window) {

    var validatorsForCompleteness = ['required', 'requiredIfModelSaved', 'uniqueEmail'];

    return {
      calculateCompleteness: calculateCompleteness,
      fulfillCompleteness: fulfillCompleteness,
      copyToClipboard: copyToClipboard
    };

    /**
     * @description
     * Fulfill form for calculate completeness for required form elements
     * @param {ngForm} form - angular form for fulfillment
     */
    function fulfillCompleteness(form) {
      var defer = $q.defer();
      var completenessStates = _fulfillFormCompleteness(form);
      var completenessResult = calculateCompleteness(
        completenessStates.completenessFormsStates,
        completenessStates.requiredFieldsStates
      );
      defer.resolve({
        isFormIncomplete: completenessResult.isFormIncomplete,
        profileCompletionPercentage: completenessResult.profileCompletionPercentage,
        completenessFormsStates: completenessStates.completenessFormsStates,
        requiredFieldsStates: completenessStates.requiredFieldsStates
      });
      return defer.promise;
    }

    function _fulfillFormCompleteness(form, completenessFormsStates, requiredFieldsStates) {
      completenessFormsStates = completenessFormsStates || {};
      requiredFieldsStates = requiredFieldsStates || {};
      _.each(form, function (item, key) {
        if (!_.startsWith(key, '$')) { // drop all special form's or field's properties
          if (item.$validators || item.$asyncValidators) { // wow it is a field
            if (_.intersection(_.keys(item.$validators), validatorsForCompleteness).length ||
              _.intersection(_.keys(item.$asyncValidators), validatorsForCompleteness).length ) {
              var isValid = !(
                item.$isEmpty(item.$modelValue) ||
                (angular.isArray(item.$modelValue) && item.$modelValue.length === 0) ||
                _.intersection(_.keys(item.$error), validatorsForCompleteness).length
              );
              var fieldState = {};
              var formState = {};
              fieldState[item.$name] = isValid;
              formState[item.$$parentForm.$name] = fieldState;
              _.assign(requiredFieldsStates, fieldState);
              _.merge(completenessFormsStates, formState);
            }
          } else { // f*ck it is a nested form
            _fulfillFormCompleteness(item, completenessFormsStates, requiredFieldsStates);
          }
        }
      });

      return {completenessFormsStates: completenessFormsStates, requiredFieldsStates: requiredFieldsStates};
    }

    /**
     * @description
     * Calculate completeness for and required fields
     * @param {Object} completenessFormsStates - collection of forms with completeness states
     * @param {Object} requiredFieldsStates - collection of required fields from all forms
     */
    function calculateCompleteness(completenessFormsStates, requiredFieldsStates) {
      var result = {};
      result.isFormIncomplete = _.mapValues(completenessFormsStates, function (i) {
        return !_.every(i);
      });
      result.profileCompletionPercentage = _.round(
        _.get(_.countBy(requiredFieldsStates), 'true', 0) / _.size(requiredFieldsStates) * 100
      );
      return result;
    }

    function copyToClipboard(text) {
      if (!$window.navigator.clipboard) {
        fallbackCopyTextToClipboard(text);
        return $q.when(true);
      }

      return $window.navigator.clipboard.writeText(text);
    }

    function fallbackCopyTextToClipboard(text) {
      var textArea = $window.document.createElement('textarea');
      textArea.value = text;
      textArea.style.position='fixed';  //avoid scrolling to bottom
      $window.document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();

      $window.document.execCommand('copy');

      document.body.removeChild(textArea);
    }
  }
})();
