(function () {

  angular.module('kmi.lms.components')
    .directive('scrollToInput', function () {
      return {
        restrict: 'A',
        link: function postLink(scope, element) {
          element.bind('click', function () {
            setTimeout(function () {
              element.get(0).scrollIntoView(true);
            }, 1000);
          });
        }
      };
    });
})();
