(function () {

  angular.module('kmi.lms.components')
    .directive('scrollObserver', function () {
      return {
        restrict: 'A',
        compile: function compile() {
          return {
            pre: function preLink(scope, iElement, iAttrs) {
              var treshold = (iAttrs.scrollTreshold || 70) / 100;
              iElement.on('scroll', function (event) {
                scope.$event = event;
                scope.$eval(iAttrs.scrollObserver);
                if (event.target.offsetHeight + event.target.scrollTop >= treshold * event.target.scrollHeight) {
                  scope.$eval(iAttrs.scrollTreshholdReached);
                }
              });
            }
          };
        }
      };
    });
})();
