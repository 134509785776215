import { Directive, ElementRef, Injector, Input } from '@angular/core';
import { UpgradeComponent } from '@angular/upgrade/static';


@Directive({
  selector: 'expand-for-more',
})
export class ExpandForMoreComponentDirective extends UpgradeComponent {
  static readonly selector = 'expandForMoreComponent';

  @Input() content: string;
  @Input() trackingName: string;
  @Input() parentId: string;
  @Input() placeUnder: string;

  constructor(elementRef: ElementRef, injector: Injector) {
    super(ExpandForMoreComponentDirective.selector, elementRef, injector);
  }
}
