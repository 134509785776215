(function () {

  angular.module('kmi.lms.components')
    .directive('emailsList', emailsList);

  function emailsList() {
    return {
      restrict: 'EA',
      scope: {
        emails: '='
      },
      template: require('ajs/components/emails/emails-list.html').default,
      controller: EmailsListCtrl,
      controllerAs: 'vm',
      bindToController: true
    };
  }

  /* @ngInject */
  function EmailsListCtrl(_) {
    var vm = this;
    vm.removeEmail = removeEmail;
    vm.addNewEmail = addNewEmail;

    function removeEmail(email) {
      vm.emails = _.without(vm.emails, email);
    }

    function addNewEmail(email) {
      if (!vm.emails) {
        vm.emails = [];
      }

      if (!_.includes(vm.emails, email)) {
        vm.emails.push(email);
        vm.emails = angular.copy(vm.emails);
      }

      vm.email = null;
    }
  }
})();
