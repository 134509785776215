(function () {

  angular.module('kmi.lms.components')
    .directive('validPositiveFloat', function () {
      return {
        require: 'ngModel',
        link: function (scope, element, attr, ngModelController) {
          ngModelController.$parsers.unshift(function (viewValue) {
            if (!viewValue) {
              ngModelController.$setValidity('float', true);
              return null;
            }

            var floatRegEx = /^\d*((\.|\,)\d+)?$/;

            if (attr.validPositiveFloat && !isNaN(attr.validPositiveFloat)) {
              var maxFractionDigits = parseInt(attr.validPositiveFloat);
              if (maxFractionDigits > 0) {
                floatRegEx = new RegExp('^\\d*((\\.|\\,)\\d{1,' + maxFractionDigits + '})?$');
              }
            }

            if (floatRegEx.test(viewValue)) {
              ngModelController.$setValidity('float', true);

              return viewValue.replace(',', '.');
            } else {
              ngModelController.$setValidity('float', false);
              return null;
            }
          });
        }
      };
    });
})();
