(function () {

  angular.module('kmi.lms.components')
    .directive('validPercentage', function () {
      return {
        require: 'ngModel',
        link: function (scope, element, attr, ngModelController) {
          ngModelController.$parsers.unshift(function (viewValue) {
            if (!viewValue) {
              ngModelController.$setValidity('percents', true);
              return null;
            }

            var floatRegEx = /^((\d{0,2}(\.\d{1,2})?)|(100(\.0{1,2})?))%?$/;

            if (floatRegEx.test(viewValue)) {
              ngModelController.$setValidity('percents', true);

              return viewValue.replace(',', '.').replace('%', '');
            } else {
              ngModelController.$setValidity('percents', false);

              return null;
            }
          });
        }
      };
    });
})();
