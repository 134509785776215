(function () {

  angular.module('kmi.lms.components')
    .directive('validPositiveInteger', function () {
      return {
        require: 'ngModel',
        link: function (scope, element, attr, ngModelController) {

          ngModelController.$parsers.unshift(function (viewValue) {
            if (!viewValue) {
              ngModelController.$setValidity('integer', true);
              return null;
            }
            let minDigitsCount = attr.minDigits || 0,
              maxDigitsCount  = attr.maxDigits || 10;

            var intRegEx = new RegExp('^\\d{' + minDigitsCount + ',' + maxDigitsCount + '}$');

            if (intRegEx.test(viewValue)) {
              ngModelController.$setValidity('integer', true);
              return viewValue;
            } else {
              ngModelController.$setValidity('integer', false);
              return null;
            }
          });
        }
      };
    });
})();
