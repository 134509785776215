(function () {

  angular.module('kmi.lms.components')
    .directive('autofocus', ['$timeout', function ($timeout) {
      return {
        restrict: 'A',
        link: function ($scope, $element) {
          $timeout(function () {
            $element[0].focus();

            if (document.activeElement !== $element[0]) {
              $element[0].scrollIntoView({
                behavior: 'smooth',
                inline: 'nearest',
                block: 'center'
              });
            }

          }, 10);
        }
      };
    }]);
})();
