(function(){

  /**
   * @ngdoc directive
   * @name kmi.lms.components.directive:yesNoValue
   *
   * @description
   * Format boolean value to Yes/No for ngModel
   */

  angular.module('kmi.lms.components')
    .directive('yesNoValue', yesNoValue);

  function yesNoValue($filter){
    return {
      restrict: 'A',
      require: 'ngModel',
      link: function(scope, element, attrs, ctrl) {
        ctrl.$formatters.unshift(function (modelValue) {
          return $filter('yesNoFilter')(modelValue);
        });

        ctrl.$parsers.unshift(function (viewValue) {
          return $filter('yesNoFilter')(viewValue, 'makeBackConvertation');
        });
      }
    };
  }


})();
