(function () {

  angular.module('kmi.lms.components')
    .directive('validDateTime', function () {
      return {
        require: 'ngModel',
        link: function (scope, element, attr, ngModelController) {
          ngModelController.$parsers.unshift(function (viewValue) {
            if (!viewValue) {
              ngModelController.$setValidity('valid_date_time', true);
              return null;
            }

            if (!isNaN(viewValue)) {
              ngModelController.$setValidity('valid_date_time', true);

              return [viewValue.getFullYear(), viewValue.getMonth() + 1, viewValue.getDate()].join('-');
            } else {
              ngModelController.$setValidity('valid_date_time', false);
              return null;
            }
          });
        }
      };
    });
})();
