(function () {

  /**
   * @ngdoc directive
   * @name kmi.lms.components.directive:requiredIfWasSaved
   *
   * @description
   * Validate input as required if it model was saved
   */

  angular.module('kmi.lms.components')
    .directive('requiredIfModelSaved', requiredIfModelSaved);

  function requiredIfModelSaved() {
    return {
      restrict: 'A',
      require: ['ngModel', '^^form'],
      link: link
    };
  }

  function link(scope, element, attrs, controllers) {
    var modelCtrl = controllers[0],
      formCtrl = controllers[1];

    modelCtrl.$validators.requiredIfModelSaved = function (modelValue) {
      return attrs.requiredIfModelSaved === 'false' || !formCtrl.$dirty || !(modelCtrl.$isEmpty(modelValue) || modelValue.length === 0);
    };
  }
})();
