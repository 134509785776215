(function() {

  /**
   * @desc Directive for automatic disabling button in modal after click and eval it expression
   * @example <button kmi-autodisable="expression"></button>
   */
  angular
    .module('kmi.lms.components')
    .directive('kmiAutodisable', KmiAutodisableDirective);

  function KmiAutodisableDirective() {

    return {
      restrict: 'A',
      scope: true,
      terminal: true,
      link: linkFunction
    };

    //////////

    function linkFunction(scope, element, attrs) {
      element.click(function(){
        element.attr('disabled', 'disabled');
        scope.$eval(attrs.kmiAutodisable);
        scope.$apply();
      });
    }
  }

})();
