(function () {

  angular.module('kmi.lms.components')
    .directive('trackLinkClick', function (gtag) {
      return {
        link: function (scope, elm, attr) {
          var trackingName = scope.$eval(attr.trackLinkClick);

          if (trackingName) {
            elm.on('click', function () {
              gtag.sendClickEvent(elm[0].href, trackingName);
            });
          }

        }
      };
    });
})();
