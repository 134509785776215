(function () {

  angular.module('kmi.lms.components')
    .directive('datepickerPopup', function () {
      return {
        restrict: 'EAC',
        require: 'ngModel',
        link: function (scope, element, attr, controller) {
          //remove the default formatter from the input directive to prevent conflict
          controller.$formatters.shift();
        }
      };
    });
})();
