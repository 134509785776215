<div class="d-flex space-between items-center">
  <div class="text-overflow-ellipsis">
    <label for="tempFile" class="btn btn-default btn-file mr-5" [ngClass]="{'disabled': disabled}">
      <input type="file" aria-label="Upload file"
             #fileInput
             [disabled]="disabled"
             [accept]="options.acceptFileTypes" name="tempFile" id="tempFile"
             (change)="onFileSelected($event)" />
      Select new file
    </label>
    <span *ngIf="tempFile">{{tempFile.name}}</span>
  </div>
  <span class="mt-5" *ngIf="tempFile">
    <a href="#" class="text-danger no-text-decoration"
       (click)="resetFile(fileInput)" title="Clear file selection">
      <i class="fa-solid fa-xmark"></i>
    </a>
  </span>
</div>
