import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DatepickerAdapter } from './datepicker.adapter';
import { DatepickerParserFormatter } from './datepicker-parser.formatter';
import { DatepickerConfig } from './datepicker.config';
import { TimepickerConfig } from './timepicker.config';
import { TimepickerAdapter } from './timepicker.adapter';
import { DatepickerComponent } from './datepicker.component';
import { DateTimeDirective } from './date-time.component.upgraded';
import { TimeIndicatorDirective } from './time-indicator.component.upgraded';
import { DateWithHiddenTimeDirective } from './date-with-hidden-time.component';
import {
  NgbDateAdapter, NgbDateParserFormatter, NgbDatepickerConfig, NgbDatepickerModule, NgbTimeAdapter,
  NgbTimepickerConfig, NgbTimepickerModule
} from '@ng-bootstrap/ng-bootstrap';
import { DateWithWeekdayComponent } from 'components/dates/date-with-weekday.component';


@NgModule({
  imports: [
    CommonModule,
    NgbDatepickerModule,
    NgbTimepickerModule,
  ],
  declarations: [
    DateTimeDirective,
    DateWithHiddenTimeDirective,
    DatepickerComponent,
    DateWithWeekdayComponent,
    TimeIndicatorDirective,
  ],
  exports: [
    DateTimeDirective,
    DateWithHiddenTimeDirective,
    DatepickerComponent,
    NgbDatepickerModule,
    NgbTimepickerModule,
    DateWithWeekdayComponent,
    TimeIndicatorDirective,
  ],
  providers: [
    { provide: NgbTimepickerConfig, useClass: TimepickerConfig },
    { provide: NgbTimeAdapter, useClass: TimepickerAdapter },
    { provide: NgbDatepickerConfig, useClass: DatepickerConfig },
    { provide: NgbDateAdapter, useClass: DatepickerAdapter },
    { provide: NgbDateParserFormatter, useClass: DatepickerParserFormatter }
  ]
})
export class LmsDatesModule { }
