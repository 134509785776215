(function () {

  angular.module('kmi.lms.components')
    .directive('mutualExclusivity', function (_) {
      return {
        require: 'ngModel',
        link: function (scope, element, attr, ngModelController) {

          ngModelController.$validators.mutualExclusivity = function (viewValue) {
            let values = scope.$eval(attr.mutualExclusivity);
            let result = _.filter(_.map(values, (v) => {
              return _.find(viewValue, v);
            }));
            return result.length <= 1;
          };
        }
      };
    });
})();
