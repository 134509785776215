(function () {

  angular
    .module('kmi.lms.components')
    .factory('scrollService', scrollService);

  /* @ngInject */
  function scrollService($window, $location, $timeout, $http, $q) {
    return {
      scrollWithOffset: scrollWithOffset,
      scrollInto: scrollInto,
      scrollToSection: scrollToSection,
      highlightSection: highlightSection,
      waitForScroll: waitForScroll,
      listenForSectionChange: listenForSectionChange,
      highlightSectionAndGo: highlightSectionAndGo
    };

    function highlightSection(sectionName) {
      let search = $location.search();
      angular.extend(search, {section: sectionName});
      $location.search(search);
    }

    function highlightSectionAndGo(sectionName, func) {
      $timeout(()=>{
        highlightSection(sectionName);
        if (angular.isFunction(func)) {
          $timeout(func);
        }
      });
    }

    function listenForSectionChange($scope, scrollToSectionCallback) {
      let scrollToSectionFunc = scrollToSectionCallback ? scrollToSectionCallback : scrollToSection;
      const locationChangeCleanup = $scope.$watch(() => {
        return $location.search();
      }, (search) => {
        if (!Number.isInteger(search.section)) {
          scrollToSectionFunc();
        }
      }, true);

      $scope.$on('$destroy', function () {
        locationChangeCleanup();
      });
    }

    function waitForScroll($scope, listen, scrollToSectionCallback) {
      let scrollToSectionFunc = scrollToSectionCallback ? scrollToSectionCallback : scrollToSection;
      return $timeout(function () {
        if (!$http.pendingRequests.length) {
          scrollToSectionFunc();
          if (listen) {
            listenForSectionChange($scope, scrollToSectionCallback);
          }
        } else {
          let waitForScrollCleanup = $scope.$watch(function () {
            return $http.pendingRequests.length;
          }, function () {
            if (!$http.pendingRequests.length) {
              waitForScrollCleanup();
              scrollToSectionFunc();
              if (listen) {
                listenForSectionChange($scope, scrollToSectionCallback);
              }
            }
          });
        }
      });
    }

    function scrollToSection(activeSection, toSection) {
      let defer = $q.defer();
      $timeout(function () {
        toSection = toSection || $location.search().section;
        if (Number.isInteger(toSection) && activeSection) {
          defer.reject();
        } else if (activeSection === toSection) {
          defer.reject();
        } else {
          scrollWithOffset($window.document, toSection, 200, true);
          if (toSection !== $location.search().section) {
            highlightSection(toSection);
          }
          defer.resolve(toSection);
        }
      });

      return defer.promise;
    }

    function getOffsetTop(element) {
      return element ? (element.offsetTop + getOffsetTop(element.offsetParent)) : 0;
    }

    function scrollWithOffset(document, idOrName, scrollOffset, offsetAll) {//find element with the give id of name and scroll to the first element it finds
      if (!idOrName) {
        $window.scrollTo({
          left: 0,
          top: 0,
          behavior: 'smooth'
        });
      }
      //check if an element can be found with id attribute
      var el = document.getElementById(idOrName);
      if (!el) {//check if an element can be found with name attribute if there is no such id
        el = document.getElementsByName(idOrName);

        el = el && el.length ? el[0] : null;
      }

      if (el) { //if an element is found, scroll to the element
        el.scrollIntoView({
          behavior: 'smooth',
          inline: 'nearest',
          block: 'center'
        });

        if (typeof(scrollOffset) === 'number') { //then move down window by size of offset of elem
          const offsetTop = offsetAll ? getOffsetTop(el) : el.getBoundingClientRect().top;

          $window.scrollTo({
            left: 0,
            top: offsetTop - scrollOffset,
            behavior: 'smooth',
          });
        }
      }
      //otherwise, ignore
    }

    function scrollInto(document, element, idOrName, block) {//find element with the give id of name and scroll to the first element it finds
      idOrName = idOrName || 'lms-main-container';

      //check if an element can be found with id attribute
      var el = document.querySelector('[id^="'+idOrName+'"]');

      if (!el) {//check if an element can be found with name attribute if there is no such id
        el = document.getElementsByName(idOrName);

        el = el && el.length ? el[0] : null;
      }

      if (el) { //if an element is found, scroll to the element
        el.scrollIntoView({behavior: 'smooth', inline: 'nearest', block: block || 'start'});
      }
      //otherwise, ignore
    }
  }
})();
