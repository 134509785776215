(function () {

  angular.module('kmi.lms.components')
    .directive('uniqueAttributeValue', uniqueAttributeValue);

  /* @ngInject */
  function uniqueAttributeValue($q, attributesService) {
    return {
      restrict: 'A',
      require: 'ngModel',
      link: function (scope, elem, attr, ctrl) {
        let requiresBeUnique = scope.$eval(attr.uniqueAttributeValue),
          savedModelValue = scope.$eval(attr.ngModel),
          instance = attr.instance,
          validationResults = {};

        if (!requiresBeUnique) {
          return;
        }

        ctrl.$asyncValidators.uniqueAttributeValue = function (modelValue) {
          if (ctrl.$isEmpty(modelValue) || !requiresBeUnique || modelValue === savedModelValue) {
            // consider empty model valid
            return $q.resolve();
          }

          var deferred = $q.defer(), typeId = scope.$eval(attr.attrTypeId);

          if (validationResults[modelValue]) {
            return validationResults[modelValue].promise;
          } else {
            validationResults[modelValue] = deferred;

            attributesService.checkUniqueAttrValue(typeId, modelValue, instance)
              .then(function (unique) {
                if (unique) {
                  deferred.resolve();
                } else {
                  deferred.reject();
                }
              });
          }

          return deferred.promise;
        };
      }
    };
  }
})();

