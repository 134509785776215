(function () {

  /**
   * @ngdoc directive
   * @name kmi.lms.components.directive:handleObjectChange
   *
   * @description
   * Set dirty when object changed
   */

  angular.module('kmi.lms.components')
    .directive('handleObjectChange', handleArrayChange);

  function handleArrayChange() {
    return {
      restrict: 'A',
      require: 'ngModel',
      link: link
    };

    function link(scope, element, attrs, ctrl) {
      scope.$watch(attrs.ngModel, function(newValue, oldValue) {
        if (!angular.equals(newValue, oldValue)){
          ctrl.$setDirty();
          ctrl.$validate();
        }
      }, true);
    }
  }
})();
