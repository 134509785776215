(function () {

  angular
    .module('kmi.lms.components')
    .filter('trimString', function () {

      return function (input, len) {
        len = parseInt(len, 10);
        if (len) {
          var result = input.toString().substr(0, len + 1);
          return (result.length >= len) ? result.replace(/.$/, '\u2026') : result;
        } else {
          return input;
        }
      };
    });
})();
