import {
  AfterViewInit,
  Directive,
  ElementRef,
  EventEmitter,
  forwardRef,
  HostListener,
  Injector,
  Input,
  Output,
  Renderer2,
  SimpleChange,
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { LmsValueAccessorUpgradeComponent } from 'components/forms/value-accessor.component.upgraded';


@Directive({
  selector: 'markdown-editor-component',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => MarkdownEditorDirective),
    }
  ]
})
export class MarkdownEditorDirective extends LmsValueAccessorUpgradeComponent<string> implements AfterViewInit {
  static readonly selector = 'markdownEditorComponent';

  @Input() id!: string;
  @Input() placeholder?: string;
  @Input() rows!: number;
  @Input() required!: boolean;
  @Input() disabled!: boolean;
  @Input() html!: boolean;
  @Input() isPreview!: boolean;
  @Input() disableAttachments!: boolean;
  @Input() content!: string;
  @Input() maxLength?: number;
  @Input() hideButtons?: string[];
  @Output() contentChange = new EventEmitter<string>();

  constructor(private elementRef: ElementRef, injector: Injector, private renderer:Renderer2) {
    super(MarkdownEditorDirective.selector, elementRef, injector);
  }

  @HostListener('focus', ['$event'])
  focus($event: FocusEvent) {
    $event.preventDefault();
    $event.stopPropagation();
    this.elementRef.nativeElement.getElementsByTagName('textarea')[0]?.focus();
  }

  ngAfterViewInit() {
    this.renderer.setAttribute(this.elementRef.nativeElement, 'tabindex', '0');
  }

  setDisabledState(isDisabled: boolean) {
    const simpleChange = new SimpleChange(this.disabled, isDisabled, false);

    this.disabled = isDisabled;
    this.ngOnChanges({ disabled: simpleChange });
  }

  protected get modelPropertyName(): string {
    return 'content';
  }


  protected get valueChanged(): EventEmitter<string> {
    return this.contentChange;
  }

  protected transformValue(value: string): string {
    return value || '';
  }
}
