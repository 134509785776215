(function () {

  angular
    .module('kmi.lms.components')
    .component('formattedDigit', {
      template: require('ajs/components/formatted-digit.html').default,
      controller: FormattedDigitController,
      controllerAs: 'vm',
      bindings: {
        digitValue: '='
      }
    });

  /* @ngInject */
  function FormattedDigitController(elms) {
    var vm = this;

    vm.formatDigit = elms.formatDigit;
  }
})();
