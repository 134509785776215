import { Injectable } from '@angular/core';
import { NgbTimepickerConfig } from '@ng-bootstrap/ng-bootstrap';


/**
 * A configuration service for the `NgbDatepicker` component.
 * https://ng-bootstrap.github.io/#/components/timepicker/examples#config
 */
@Injectable()
export class TimepickerConfig extends NgbTimepickerConfig {
  meridian = true;
  hourStep = 1;
  minuteStep = 15;
  secondStep = 15;
  spinners = true;
  seconds = false;
}
