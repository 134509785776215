<form ngForm (ngSubmit)="submit()">
  <div class="modal-header">
    <button type="button" class="close" aria-label="Close" (click)="cancel()"><span aria-hidden="true">&times;</span>
    </button>
    <h4 class="modal-title">Insert Hyperlink</h4>
  </div>

  <div class="modal-body">
    <label for="url">Link</label>
    <input type="url" id="url" [(ngModel)]="url" class="form-control" #urlInput="ngModel" name="url"
           required
           [lmsPattern]="regex">
  </div>

  <div class="modal-footer">
    <button class="btn btn-warning" (click)="cancel()">Cancel</button>
    <button class="btn btn-success" [disabled]="urlInput.invalid">Submit</button>
  </div>
</form>
