angular.module('kmi.lms.components')
  .factory('fileUploadService', ['$http', '$q', ($http, $q) => {
    return {
      getService: getService
    };

    function getService(config) {
      return new FileUploadService(config);
    }

    function FileUploadService(config) {
      var vm = this;
      vm.upload = upload;

      function upload(file: any, uid: string, trackProgress, formData?: object) {
        if (!validateFileSize(file)) {
          return $q.reject('File size exceeds limit');
        }

        let fd = new FormData();
        if (formData) {
          Object.keys(formData).forEach(key => {
            fd.append(key, formData[key]);
          });
        }

        fd.append('file', file);
        return $http.post(
          config.endPoint.replace('{{globalId}}', uid),
          fd,
          {
            transformRequest: angular.identity,
            headers: {'Content-Type': undefined},
            uploadEventHandlers: {
              progress: function (e) {
                if (e.lengthComputable && trackProgress) {
                  trackProgress({loaded: e.loaded, total: e.total});
                }
              }
            }
          });
      }

      function validateFileSize(file) {
        return file.size <= config.maxFileSize * 1024 * 1024;
      }
    }
  }]);
