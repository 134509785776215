(function () {

  angular.module('kmi.lms.components')
    .filter('semicolonJoin', function () {
      return function (input) {
        if (input) {
          if (angular.isArray(input)) {
            return input.join(';');
          }
          return input + '';
        }

        return '';
      };
    });
})();
