import { Component, Input } from '@angular/core';


@Component({
  selector: 'read-time',
  templateUrl: './read-time.component.html'
})
export class ReadTimeComponent  {
  readTime = 0;

  @Input() set text(txt: string) {
    this.readTime = this.calculate(txt);
  }

  get text(): string {
    return '';
  }

  private calculate(text: string): number {
    if (text) {
      const wordsPerMinute = 200;
      const noOfWords = text.split(/\s/g).length;
      const minutes = noOfWords / wordsPerMinute;

      return Math.ceil(minutes);
    }

    return 0;
  }
}
