import { Pipe, PipeTransform } from '@angular/core';
import { ElmsUtils } from 'core/utils';


@Pipe({ name: 'htmlToPlainText' })
export class HtmlToPlainTextPipe implements PipeTransform {
  transform(value: string, spaceDelimiter?: boolean) {
    return ElmsUtils.htmlToPlainText(value, spaceDelimiter);
  }
}
