import { Directive, ElementRef, EventEmitter, HostListener, OnInit, Output, Renderer2 } from '@angular/core';


@Directive({
  selector: '[mimicButton]'
})
export class MimicButtonDirective implements OnInit {
  @Output() mimicButton: EventEmitter<UIEvent> = new EventEmitter<UIEvent>();

  constructor(private el: ElementRef, private renderer: Renderer2) { }

  @HostListener('click', ['$event'])
  onClick(event: MouseEvent) {
    this.mimicButton.emit(event);
  }

  @HostListener('keydown.enter', ['$event'])
  onEnter(event: KeyboardEvent) {
    this.mimicButton.emit(event);
  }

  @HostListener('keydown.space', ['$event'])
  onSpace(event: KeyboardEvent) {
    this.mimicButton.emit(event);
  }

  ngOnInit(): void {
    this.renderer.setAttribute(this.el.nativeElement, 'role', 'button');
    this.renderer.setAttribute(this.el.nativeElement, 'tabindex', '0');
  }
}
