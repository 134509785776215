(function () {

  /*
   * This directive fill fix browser issues like this:
   *      https://code.google.com/p/chromium/issues/detail?can=2&q=autofill&colspec=ID%20Pri%20M%20Iteration%20ReleaseBlock%20Cr%20Status%20Owner%20Summary%20OS%20Modified&id=352527&thanks=352527&ts=1394783497
   *      https://code.google.com/p/chromium/issues/detail?id=486859&q=autofill&colspec=ID%20Pri%20M%20Week%20ReleaseBlock%20Cr%20Status%20Owner%20Summary%20OS%20Modified
   *      https://code.google.com/p/chromium/issues/detail?id=451424&q=autofill&sort=os&colspec=ID%20Pri%20M%20Week%20ReleaseBlock%20Cr%20Status%20Owner%20Summary%20OS%20Modified
   * This issue appears only in Chrome for iOS. Model won't be changed based on the autofill.
   * This solution from the: http://stackoverflow.com/questions/14965968/angularjs-browser-autofill-workaround-by-using-a-directive
   */

  angular.module('kmi.lms.components')
    .directive('syncModels', function () {
      return {
        link: function (scope, element, attrs) {
          scope.$on(attrs.syncModels, function () {
            var inputs = element.find('input');

            for (var i = 0; i < inputs.length; i++) {
              var elem = angular.element(inputs[i]);
              var ngModel = elem.controller('ngModel');
              var value = elem.val();

              if (ngModel && value.length > 0) {
                ngModel.$setViewValue(value.trim());
              }
            }
          });
        }
      };
    });
})();
