(function () {

  angular.module('kmi.lms.components')
    .directive('validateEquality', function () {
      return {
        restrict: 'A',
        require: 'ngModel',
        link: function (scope, elm, attrs, ctrl) {
          function validateEquality(otherValue) {
            if (attrs.equalitySensitive === 'false' && ctrl.$modelValue && otherValue
              && ctrl.$modelValue.toLowerCase() === otherValue.toLowerCase()) {
              ctrl.$setValidity('equal', true);
              return ctrl.$modelValue;
            } else if (ctrl.$modelValue === otherValue) {
              ctrl.$setValidity('equal', true);
              return ctrl.$modelValue;
            }

            ctrl.$setValidity('equal', false);
            return undefined;
          }

          scope.$watch(attrs.validateEquality, function (otherModelValue) {
            validateEquality(otherModelValue);
          });

          scope.$watch(function () {
            return ctrl.$modelValue;
          }, function () {
            return validateEquality(scope.$eval(attrs.validateEquality));
          });
        }
      };
    });
})();
