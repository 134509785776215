(function () {

  angular
    .module('kmi.lms.components')
    .factory('treeNodesService', treeNodesService);

  function treeNodesService(_) {

    return {
      checkAll: checkAll,
      getCheckedNodes: getCheckedNodes,
      getCheckedLeaves: getCheckedLeaves,
      setParents: setParents,
      setCheckedNodes: setCheckedNodes,
      setVisibleForSelected: setVisibleForSelected
    };

    function checkAll(node) {
      if (node.nodes && node.nodes.length > 0) {
        node.expanded = true;
        propagateChecked(node.nodes, node.checked);
      }
      // uncheck/check upward
      var parent;
      if (!node.checked) {
        parent = node.parent;
        while (parent) {
          parent.checked = false;
          parent = parent.parent;
        }
      } else {
        parent = node.parent;
        while (parent && isAllChildrenChecked(parent)) {
          parent.checked = true;
          parent = parent.parent;
        }
      }
    }

    function isAllChildrenChecked(node) {
      if (!node.nodes || !node.nodes.length) {
        return false;
      }

      for (var i = node.nodes.length - 1; i >= 0; i--) {
        if (!node.nodes[i].checked) {
          return false;
        }
      }
      return true;
    }

    function propagateChecked(nodes, checked) {
      for (var i = 0; i < nodes.length; i++) {
        nodes[i].checked = checked;
        nodes[i].expanded = true;
        if (nodes[i].nodes && nodes[i].nodes.length > 0) {
          propagateChecked(nodes[i].nodes, checked);
        }
      }
    }

    function getCheckedNodes(nodes) {
      var childNodes = _.reduce(nodes, function (flattened, node) {
        return flattened.concat(node.nodes || []);
      }, []);
      var checkedNodes = _.filter(childNodes, function (n) {
        return n.checked && n.id;
      });
      if (childNodes.length > 0) {
        return checkedNodes.concat(getCheckedNodes(childNodes));
      }
      return checkedNodes;
    }

    function getCheckedLeaves(nodes) {
      var checkedNodes = getCheckedNodes(nodes);
      return _.filter(checkedNodes, function (node) {
        return !node.nodes || node.nodes.length === 0;
      });
    }

    function setParents(nodes, parent) {
      if (nodes.length > 0) {
        _.forEach(nodes, function (node) {
          node.parent = parent;
          if (node.nodes) {
            setParents(node.nodes, node);
          }
        });
      }
    }

    function setCheckedNodes(nodes, selectedItems) {
      let hasChecked = false;
      _.forEach(nodes, function (node) {
        if (node.modelId){
          node.checked = _.some(selectedItems, {id: node.id});
        }
        hasChecked = hasChecked || node.checked;

        if (setCheckedNodes(node.nodes, selectedItems)) {
          hasChecked = node.expanded = true;
          node.checked = _.every(node.nodes, 'checked');
        }
      });
      return hasChecked;
    }

    function setVisibleForSelected(nodes) {
      _.forEach(nodes, function (node) {
        node.visible = node.checked || node.expanded;
        if (node.nodes) {
          setVisibleForSelected(node.nodes);
        }
      });
    }

  }
})();
