(function(){

  angular.module('kmi.lms.components')
    .directive('iframeOnload', function() {
      return {
        scope: {
          callBack: '&iframeOnload'
        },
        link: function (scope, element) {
          element.on('load', function () {
            return scope.$apply(scope.callBack);
          });
        }
      };
    });
})();
